<template>
  <div class="d-flex w-100">
    <b-card class="w-100 min-h-60 mobile-transparent">
      <router-view />
    </b-card>
    <context-menu ref="context" right>
      <b-button
        class="text-left d-flex align-items-center justify-content-between px-2"
        block
        :disabled="menu.disabled"
        v-for="(menu, index) in menus"
        :key="index"
        @click="handleSelectMenu(menu)"
        :variant="selectedMenu.url === menu.url ? 'primary' : 'light'"
      >
        <span> {{ menu.name }}</span>
        <b-icon
          v-b-popover.hover.top="variantStatus(menu.status).label"
          :icon="variantStatus(menu.status).icon"
          :variant="variantStatus(menu.status).variant"
          class="ml-3"
        />
      </b-button>
    </context-menu>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      homologationId: "",
      serviceId: "",
      selectedMenu: "",
      menus: [],
    };
  },
  computed: {
    ...mapGetters(["service_validation_history", "user"]),
  },
  methods: {
    variantStatus(status) {
      let icon;
      let variant;
      let label;
      switch (status) {
        case 1:
          icon = "check-circle";
          variant = "success";
          label = "Valiação concluída";
          break;
        case 2:
          icon = "x-circle";
          variant = "danger";
          label = "Não há dados para validar";
          break;
        case 3:
          icon = "clock";
          variant = "warning";
          label = "Aguardando validação";
          break;
      }
      return {
        icon,
        variant,
        label,
      };
    },
    selectedRoute() {
      const routeMenu = this.$route.fullPath
        .split("/")
        .filter((path) => path)
        .slice(-1)[0];
      this.selectedMenu = this.menus.find(({ url }) => url === routeMenu) || {
        status: 1,
      };
    },

    handleSelectMenu(menu) {
      if (this.selectedMenu.url === menu.url) {
        return;
      }
      this.selectedMenu = menu;
      this.$router.push(
        `/homologation/${this.homologationId}/service/${this.serviceId}/${this.user.role}/regularity/${menu.url}`
      );
      this.$refs.context.close();
    },
  },
  mounted() {
    this.basePath = `/homologation/${this.homologationId}/service/${this.serviceId}/${this.user.role}/regularity/`;
    this.homologationId = this.$route.params.homologation_id;
    this.serviceId = this.$route.params.service_id;
  },
  watch: {
    service_validation_history(menus) {
      if (menus.length > 0) {
        const currentMenu = (this.menus = menus.find(
          ({ base }) => base === "regularity"
        ));
        this.menus = currentMenu.children;
        this.selectedRoute();
      }
    },
  },
};
</script>
